// Hintergrundfarbe ändern umfärben
const architekten = document.getElementById('architekten');
gsap.timeline({
    scrollTrigger: {
        id: 'architektenHintergrundfarbe',
        trigger: architekten,
        start: 'top top',
        end: 'top top',
        onEnter: () => document.body.setAttribute('data-hintergrund', 'weiss'),
        onLeaveBack: () => document.body.setAttribute('data-hintergrund', 'mitternachtsblau'),
        // markers: true,
    }
});

// HEADER BEIM SCROLLEN UMFÄRBEN
const logo = document.getElementById('header__logo');
const logoLink = document.getElementById('header__logo-link');
const buttons = document.querySelectorAll('.header__button');
const buttonsText = document.querySelectorAll('.header__button-text');

// Die Start- und Endpositionen beziehen sich auf den Moment,
// in dem die blaue Kante von architekten am oberen Viewportrand ist (trigger).
// Daher muss die Position nach oben gezogen werden (mal -1)

// Logo umfärben
gsap.timeline({
    scrollTrigger: {
        trigger: architekten,
        start:() => `${(logo.offsetTop + logoLink.getBoundingClientRect().height) * -1} top`,
        end:() => `${logo.offsetTop * -1} top`,
        scrub: true,
        // markers: true
        invalidatenRefresh: true,
    }
})
    .to(logoLink, {
        immediateRender: true,
        ease: 'none',
        backgroundPositionY: '100%'
    }, 0)

// Buttons umfärben
buttons.forEach(function (btn) {
    gsap.timeline({
        scrollTrigger: {
            trigger: architekten,
            start:() => `${(btn.offsetTop + btn.getBoundingClientRect().height) * -1} top`,
            // Der Button stösst direkt an die Fläche von architekten, daher kann für end die Höhe verwendet werden
            end:() => `${btn.offsetTop * -1} top`,
            scrub: true,
            // markers: true
            invalidatenRefresh: true,
        }
    })
        .to(btn, {
            immediateRender: true,
            ease: 'none',
            backgroundPositionY: '100%'
        }, 0)
});

// Buttons Text umfärben
buttonsText.forEach(function (btn) {
    gsap.timeline({
        scrollTrigger: {
            trigger: architekten,
            start:() => `${(btn.offsetTop + btn.getBoundingClientRect().height) * -1} top`,
            end:() => `${btn.offsetTop * -1} top`,
            scrub: true,
            onEnter: () => {
                document.body.setAttribute('data-header-hintergrund', 'rauchweiss');
            },
            onLeaveBack: () => {
                document.body.setAttribute('data-header-hintergrund', 'mitternachtsblau');
            },
            // markers: true
            invalidatenRefresh: true,
        }
    })
        .to(btn, {
            immediateRender: true,
            ease: 'none',
            backgroundPositionY: '100%'
        }, 0)
});

architektenTextContainer = document.getElementById('architekten__text-container');
architektenBilderblock1 = document.getElementById('architekten__bilderblock-1');
gsap.registerPlugin(ScrollTrigger);

// Architekten Text pinnen, bis er ganz lesbar gewesen ist (die Bilder über ihn hinweg gescrollt sind)
ScrollTrigger.create({
    trigger: architektenTextContainer,
    pin: architektenTextContainer,
    start: 'top-=132px top',
    end: () => `${architektenBilderblock1.getBoundingClientRect().height} top`,
    // markers: true
});

// Bildgruppe 2 nach dem Pin beschleunigen
// architektenBilderblock2 = document.getElementById('architekten__bilderblock-2');

// gsap.to(architektenBilderblock2, {
//     y: "-20vh",
//     scrollTrigger: {
//         trigger: architektenTextContainer,
//         // Die Unterkante von architekten__text (Titel und Texte)
//         start: self => self.previous().end,
//         end: 'bottom bottom',
//         scrub: true,
//         markers: { startColor: "blue", endColor: "goldenrod" }
//     }
// });


// const bereits in angebotContainer.js definiert
if (architekten !== null) {
    architekten.addEventListener('mouseover', function() {
        document.body.removeAttribute('data-maushintergrund');
    });
}